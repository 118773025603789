/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): sidebar.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.constructor,
.pmd-content {
	position: relative;
	margin: 0;
	padding-top: $content-padding-top;
	padding-left: $content-padding-x;
	padding-right: $content-padding-x;
	padding-bottom: $content-padding-bottom;
	@include transition($sidebar-transition);
}

@include media-breakpoint-down(sm) {
	body.pmd-body-open {
		overflow: hidden;
	}
}

.pmd-sidebar,
.wrapper,
.pmd-content {
	vertical-align: top;
}

// Sidebar 
.pmd-sidebar {
	position: relative;
	display: block;
	// overflow-y: auto;
	// overflow-x: hidden;
	border: none;
	@include transition($sidebar-transition);
	padding-top: $sidebar-padding-top;
	width: $sidebar-width;

	@include media-breakpoint-down(sm) {
		padding-top: 0;
		transform: translate3d(-$sidebar-width, 0, 0);
	}

	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}

	&::-webkit-scrollbar-track {
		border-radius: $sidebar-scrollbar-border-radius;
	}

	&::-webkit-scrollbar-track {
		border-radius: $sidebar-scrollbar-border-radius;
	}

	&::-webkit-scrollbar {
		width: $sidebar-scrollbar-width;
		background-color: $sidebar-scrollbar-bg;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: $sidebar-scrollbar-thumb-border-radius;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $sidebar-scrollbar-thumb-bg;
	}

	// Sidebar My account 
	.pmd-user-info {
		>a {
			background-color: $sidebar-user-info-bg;
			background-size: cover;

			@include hover-focus {
				background-color: $sidebar-user-info-bg;
			}

			@include media-breakpoint-down(sm) {
				padding-top: $user-info-mobile-padding-y;
				padding-bottom: $user-info-mobile-padding-y;
			}
		}

		margin-bottom: calc($spacer / 2);
	}

	// Sidebar show/hide
	&.pmd-sidebar-open {
		min-width: 210px;
		width: 210px;
		@include transform-translate3d(0, 0, 0);

		@media (min-width:1560px) {
			min-width: $sidebar-width;
			width: $sidebar-width;
		}

		@include pmd-box-shadow($sidebar-box-shadow);
	}
}

// Sidebar Stacked 
.pmd-sidebar-slide-push {
	left: 0;
	@include transform-translate3d(-$sidebar-width, 0, 0);

	@include media-breakpoint-down(sm) {
		left: 0;
	}

	&.pmd-sidebar-open {

		~.pmd-content,
		~.wrapper .constructor {

			margin-left: 0;

			@media (min-width:1200px) {
				margin-left: 210px;
			}

			@media(min-width:1560px) {
				margin-left: $sidebar-width;
			}
		}

		.pmd-sidebar-nav {
			.nav-link {
				.material-icons {
					opacity: $sidebar-open-nav-icon-opacity;
				}
			}
		}
	}
}

// Left and Right Sidebar
.pmd-sidebar-left-fixed,
.pmd-sidebar-right-fixed,
.pmd-sidebar-slide-push {
	position: fixed;
	top: 0;
	bottom: 0;
}

.pmd-sidebar-left-fixed,
.pmd-sidebar-right-fixed {
	z-index: 1124;
	padding-top: 0;
}

.pmd-sidebar-left-fixed {
	left: 0;
	@include pmd-box-shadow($sidebar-box-shadow);
	@include transform-translate3d(-$sidebar-width, 0, 0);
}

.pmd-sidebar-right-fixed {
	right: 0;
	@include transform-translate3d($sidebar-width, 0, 0);

	&.pmd-sidebar-open {
		@include transform-translate3d(0, 0, 0);
	}
}

/* -- Topbar --*/
.topbar-fixed {
	@include transform-translate3d(0, 0, 0);
	@include transition($sidebar-transition);
	position: fixed;
	z-index: 1030;
	overflow: hidden;
	width: 100%;
	height: 0px;
	right: 0;
	top: 0;

	&.pmd-sidebar-open {
		@include transform-translate3d(0, 0, 0);
		width: 100%;
		height: 200%;

		&::before {
			border-radius: 50%;
			display: block;
			height: 3000px;
			width: 3000px;
			@include transform-scale(1);
			opacity: 1;
			left: 50%;
			bottom: 50%;
			margin-left: -1500px;
			margin-bottom: -1500px;
		}

		.topbar-container {
			opacity: 1;
			transition-delay: 1s;
		}
	}

	&::before {
		background: white none repeat scroll 0 0;
		border-radius: 50%;
		bottom: 100%;
		color: #fff;
		content: "";
		left: 100%;
		position: absolute;
		@include transform-origin(top right);
		@include transform-scale(0);
		@include transition($sidebar-transition);
		opacity: 0;
		height: 3000px;
		width: 3000px;
	}

	.topbar-container {
		opacity: 0;
		@include transition($sidebar-transition);
		transition-delay: 0s;
	}
}

.topbar-close {
	margin-top: 12px;
}

.pmd-sidebar-nav {
	padding: $sidebar-nav-padding-x $sidebar-nav-padding-y;

	.nav-item {
		padding: $sidebar-nav-item-padding-x $sidebar-nav-item-padding-y;

		@include media-breakpoint-up(xxl) {
			padding: 5px $sidebar-nav-item-padding-y;
		}
	}

	.nav-link {
		padding: $sidebar-nav-link-padding-x $sidebar-nav-link-padding-y;
		color: $sidebar-nav-link-color;
		font-weight: $sidebar-nav-link-font-weight;
		display: flex;
		flex-direction: row;
		-ms-flex-direction: row;
		border-radius: $sidebar-nav-link-border-radius;
		font-size: $sidebar-nav-link-font-size;
		align-items: center;
		text-decoration: none;

		.material-icons {
			color: $sidebar-nav-icon-color;
			opacity: $sidebar-nav-icon-opacity;

			svg {
				pointer-events: none;
			}
		}

		@include hover-focus {
			background-color: $sidebar-nav-link-hover-bg;
			color: $sidebar-nav-link-hover-color;
			box-shadow: $sidebar-nav-link-hover-box-shadow;

			.material-icons {
				color: $sidebar-dark-nav-icon-hover-color;
				opacity: $sidebar-nav-icon-hover-opacity;

				svg {
					fill: $sidebar-dark-nav-icon-hover-color;
				}
			}
		}

		&.active {
			background-color: $sidebar-nav-link-hover-bg;
			color: $sidebar-nav-link-hover-color;
		}
	}

	.nav-item {
		ul {
			list-style: none;
			padding-left: 0;
			border-bottom: solid $sidebar-nav-divider-width $sidebar-nav-divider-color;

			.nav-item {
				&:last-child {
					margin-bottom: calc($spacer / 2);
				}

				.nav-link {
					font-weight: $sidebar-sub-nav-link-font-weight;
					padding: $sidebar-sub-nav-link-padding-x $sidebar-nav-link-padding-y $sidebar-sub-nav-link-padding-x 72px;

					i {
						margin-left: -56px;
					}
				}

				ul {
					border-bottom: none;
				}
			}
		}

		.show {
			background-color: $sidebar-nav-link-hover-bg;
		}
	}

}

.pmd-nav-item-divider {
	height: 0;
	margin: calc($spacer / 2) 0;
	overflow: hidden;
	border-top: $sidebar-nav-divider-width solid $sidebar-nav-divider-color;
}

.pmd-sidebar-dark {
	.nav-link {
		color: $sidebar-dark-nav-link-color;

		@include hover-focus {
			.material-icons {
				color: $sidebar-dark-nav-icon-hover-color;

				svg {
					fill: $sidebar-dark-nav-icon-hover-color;
				}
			}
		}

		.material-icons {
			color: $sidebar-dark-nav-icon-color;

			svg {
				fill: $sidebar-dark-nav-link-color;
			}
		}
	}
}

// Sidebar Overlay 
.pmd-sidebar-overlay {
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	background: $sidebar-overlay-bg;
	z-index: 1030;
	@include transition($sidebar-transition);
	@include transform-translateZ(0);

	// Overlay Active
	&.pmd-sidebar-overlay-active {
		opacity: $sidebar-overlay-opacity;
		visibility: visible;
		transition-delay: 0;
		pointer-events: none;
	}
}

.pmd-sidebar-toggle {
	margin-left: -$sidebar-toggler-margin-right;
}

.pmd-nav-item-icon {
	width: $pmd-nav-item-icon-size;
	margin-right: $pmd-nav-item-icon-margin-left;
}

@import "sidebar-with-brand";