/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): textfield.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// input focus bar
.pmd-textfield-focused {
	@include transition($textfield-transition);
	width: 100%;
	height: $textfield-focused-height;
	display: block;
	top: -1px;
	background-color: $textfield-focused-bg;
	@include transform-scalex(0);
	position: relative;
	z-index: 2;
}

.pmd-textfield {
	display: block;

	&.pmd-textfield-floating-label-active {
		.pmd-textfield-focused {
			@include transform-scalex(1);
		}
	}

	&.pmd-textfield-is-invalid {
		~.invalid-feedback {
			display: block;
		}
	}

	.form-control,
	.form-select {
		// background-color: transparent;
		border: none;
		border-bottom: solid $textfield-border-height $textfield-border-color;
		outline: none;
		box-shadow: none;
		padding: 0;
		border-radius: 0;
		font-size: 0.75rem;
		padding: $textfield-padding-top 0 $textfield-padding-bottom;
		color: $textfield-color;
		height: 100%;

		&:-webkit-autofill,
		&:-webkit-autofill:hover,
		&:-webkit-autofill:focus {
			-webkit-box-shadow: none;
			transition: background-color 5000s ease-in-out 0s;
			-webkit-text-fill-color: $textfield-color;
		}
	}

	.form-select {
		background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down.svg) no-repeat right 0.35rem center/22px 22px;
		padding-left: $textfield-padding-y;
	}

	.form-select.is-invalid,
	.was-validated .form-select:invalid {
		background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down.svg) no-repeat right 0.35rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
	}

	.form-select.is-valid,
	.was-validated .form-select:invalid {
		background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down.svg) no-repeat right 0.35rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
	}

	label {
		font-weight: $textfield-label-font-weight;
		line-height: $textfield-label-line-height;
		font-size: $textfield-label-font-size;
		color: $textfield-label-color;
		margin-bottom: 0;

		&.col-form-label-lg,
		&.col-form-label-sm {
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	textarea.form-control {
		height: auto;
	}

	.col-form-label {
		font-size: inherit;
		color: $textfield-color;
		line-height: $textfield-line-height;
	}

	.form-control-lg {
		font-size: $textfield-font-size-lg;
	}

	.form-control-sm {
		font-size: $textfield-font-size-sm;
	}

	.col-form-label-lg {
		font-size: $textfield-floating-label-font-size-lg;
	}

	.col-form-label-sm {
		font-size: $textfield-floating-label-font-size-sm;
	}

	.form-select::after {
		font-family: "Material icons";
		content: "\E5C5";
	}

	.form-control-plaintext {
		outline: none;
	}

	&.pmd-textfield-is-valid {
		.valid-feedback {
			display: block;
		}
	}

	&.pmd-textfield-is-invalid {
		.invalid-feedback {
			display: block;
			padding-left: $textfield-outline-padding-x;
		}
	}

	//-------------------
	// Textfield Outline
	//-------------------
	&.pmd-textfield-outline {

		.form-control,
		.form-select {
			border: $textfield-outline-border-height solid $textfield-outline-border-color;
			border-radius: $textfield-outline-border-radius;
			padding: calc(.6rem - 1px) calc(1rem - 1px);

			@media (min-width : 1560px) {
				padding: $textfield-outline-no-floating-padding-y $textfield-outline-no-floating-padding-x;
			}
		}

		.form-control-sm {
			padding: $textfield-outline-no-floating-padding-y-sm $textfield-outline-no-floating-padding-x-sm;
		}

		// Textfield with Floating Label
		.pmd-textfield-outline-wrapper {
			position: relative;

			.form-control:not([placeholder]),
			.form-select:not([placeholder]) {
				border: none;
				padding: $textfield-outline-padding-y $textfield-outline-padding-x;
			}

			// textarea.form-control:not([placeholder]) {
			// 	margin-top: $textfield-outline-padding-y;
			// 	display: inline-flex;
			// 	padding-top: 0;
			// }

			.form-control-sm:not([placeholder]) {
				padding: $textfield-outline-padding-y-sm $textfield-outline-padding-x-sm;
				height: $textfield-outline-height-sm;
			}

			label {
				transform: translateY(-11px);
			}
		}

		.pmd-textfield-label-wrapper {
			display: flex;
			position: absolute;
			right: 0;
			left: 0;
			box-sizing: border-box;
			width: 100%;
			max-width: 100%;
			height: 100%;
			text-align: left;
			pointer-events: none;
		}

		.pmd-textfield-outline-left,
		.pmd-textfield-outline-right,
		.pmd-textfield-outline-middle {
			box-sizing: border-box;
			height: 100%;
			transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
			border-top: $textfield-outline-border-height solid $textfield-outline-border-color;
			border-bottom: $textfield-outline-border-height solid $textfield-outline-border-color;
			pointer-events: none;
		}

		.pmd-textfield-outline-left {
			border-radius: $textfield-outline-border-radius 0 0 $textfield-outline-border-radius;
			border-left: $textfield-outline-border-height solid $textfield-outline-border-color;
			border-right: none;
			width: 8px;
		}

		.pmd-textfield-outline-right {
			border-radius: 0 $textfield-outline-border-radius $textfield-outline-border-radius 0;
			border-left: none;
			border-right: $textfield-outline-border-height solid $textfield-outline-border-color;
			flex-grow: 1;
		}

		.pmd-textfield-outline-middle {
			padding-left: $textfield-outline-border-radius;
			padding-right: $textfield-outline-border-radius;
			border-top: none;
		}

		&.pmd-textfield-floating-label {
			label {
				@include transform-translateY(14px);

				&.col-form-label-lg {
					@include transform-translateY(18px);
				}

				&.col-form-label-sm {
					@include transform-translateY(4px);
				}
			}

			.pmd-textfield-outline-middle {
				border-top: $textfield-outline-border-height solid $textfield-outline-border-color;
			}

			&.pmd-textfield-floating-label-completed {
				label {
					transform: translateY(-11px);
				}

				.pmd-textfield-outline-middle {
					border-top: none;
				}
			}
		}

		&.pmd-textfield-floating-label-active {
			label {
				color: $textfield-outline-focused-bg;
				transform: translateY(-11px);
			}

			.pmd-textfield-outline-left,
			.pmd-textfield-outline-right,
			.pmd-textfield-outline-middle {
				border-top: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
				border-bottom: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
			}

			.pmd-textfield-outline-left {
				border-left: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
			}

			.pmd-textfield-outline-right {
				border-right: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
			}

			.pmd-textfield-outline-middle {
				border-top: none;
			}
		}

		&.pmd-textfield-floating-label-active {

			.form-control,
			.form-select {
				border: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
				padding: $textfield-outline-no-floating-focused-padding-y $textfield-outline-no-floating-focused-padding-x;
				box-shadow: $textfield-outline-focused-box-shadow;
			}
		}

		&.pmd-textfield-is-valid {
			.valid-feedback {
				display: block;
			}

			.pmd-textfield-outline-left,
			.pmd-textfield-outline-right,
			.pmd-textfield-outline-middle {
				border-color: theme-color("success");
			}

			label {
				color: theme-color("success");
			}
		}

		&.pmd-textfield-is-invalid {
			.invalid-feedback {
				display: block;
				padding-left: $textfield-outline-padding-x;
			}

			.pmd-textfield-outline-left,
			.pmd-textfield-outline-right,
			.pmd-textfield-outline-middle {
				border-color: theme-color("danger");
			}

			label {
				color: theme-color("danger");
			}
		}
	}



	// Textfield Filled
	&.pmd-textfield-filled {
		position: relative;

		.form-control,
		.form-select {
			background-color: $textfield-filled-bg;
			padding: $textfield-filled-no-floating-padding-y $textfield-filled-no-floating-padding-x;
			height: $textfield-filled-height;
		}

		.form-control-sm {
			padding: $textfield-outline-no-floating-padding-y-sm $textfield-outline-no-floating-padding-x-sm;
			height: $textfield-outline-height-sm;
		}

		.pmd-textfield-filled-wrapper {
			background-color: $textfield-filled-bg;
			border-radius: $textfield-filled-border-radius $textfield-filled-border-radius 0 0;
			position: relative;
			display: flex;

			.form-control:not([placeholder]),
			.form-select:not([placeholder]) {
				padding: $textfield-filled-padding-top $textfield-filled-padding-x $textfield-filled-padding-bottom $textfield-filled-padding-x;
				background-color: transparent;
			}

			textarea.form-control:not([placeholder]) {
				display: inline-flex;
				margin-top: $textfield-filled-padding-top;
				padding-top: 0;
			}

			label {
				padding-left: $textfield-filled-padding-x;
				display: flex;
				position: absolute;
				right: 0;
				left: 0;
				box-sizing: border-box;
				width: 100%;
				max-width: 100%;
				height: 100%;
				text-align: left;
				pointer-events: none;
				transform: translateY(8px);
				font-size: $textfield-filled-label-font-size;

				&.col-form-label {
					font-size: inherit;
				}
			}
		}

		&.pmd-textfield-floating-label {
			label {
				@include transform-translateY(18px);
				font-size: $textfield-floating-label-font-size;

				&.col-form-label-lg {
					font-size: $textfield-font-size-lg;
					@include transform-translateY(22px);
				}

				&.col-form-label-sm {
					font-size: $textfield-font-size-sm;
					@include transform-translateY(13px);
				}
			}

			&.pmd-textfield-floating-label-completed {
				label {
					transform: translateY(8px);
					font-size: $textfield-filled-label-font-size;
				}

				&.col-form-label-sm {
					@include transform-translateY(8px);
				}
			}
		}

		&.pmd-textfield-floating-label-active {
			label {
				color: $textfield-outline-focused-bg;

				&.is-invalid {
					color: theme-color("danger");
				}

				&.is-valid {
					color: theme-color("success");
				}
			}
		}

		.pmd-textfield-focused {
			left: 0;
			position: absolute;
			bottom: 0;
			top: inherit;
			width: auto;
			right: 0;
			height: $textfield-filled-focused-border-height;
		}
	}
}

.pmd-textfield-outline,
.pmd-custom-file-outline {

	.form-text,
	.valid-feedback,
	.invalid-feedback {
		padding-left: $textfield-outline-padding-x;
	}
}

.pmd-textfield-filled,
.pmd-custom-file-filled {

	.form-text,
	.valid-feedback,
	.invalid-feedback {
		padding-left: $textfield-filled-padding-x;
	}
}

// floating label
.pmd-textfield-floating-label {
	position: relative;

	label {
		@include transform-translateY(26px);
		font-size: $textfield-floating-label-font-size;
		transition-duration: 0.2s;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

		&.col-form-label-lg {
			font-size: $textfield-font-size-lg;
			@include transform-translateY(30px);
		}

		&.col-form-label-sm {
			font-size: $textfield-font-size-sm;
			@include transform-translateY(26px);
		}
	}

	.form-control {
		position: relative;
	}

	&.pmd-textfield-floating-label-completed {
		label {
			@include transform-translateY(0px);
			font-size: $textfield-label-font-size;
			color: $textfield-label-color;

			&.col-form-label-lg {
				font-size: $textfield-floating-label-font-size-lg;
				@include transform-translateY(0);
			}

			&.col-form-label-sm {
				font-size: $textfield-floating-label-font-size-sm;
				@include transform-translateY(0);
			}
		}
	}
}

.pmd-input-group,
.pmd-input-group-outline,
.pmd-input-group-filled {
	.form-control[type="file"] {
		position: relative;
		font-size: $textfield-font-size;
		color: $textfield-color;
		padding: $textfield-padding-y;

		&::-webkit-file-upload-button {
			visibility: hidden;
			display: none;
		}

		&::before {
			// content: 'Browser';
			outline: none;
			cursor: pointer;
			border-left: none;
			@include border-radius(4);
			background-color: $custom-file-bg;
			color: $custom-file-color;
			position: absolute;
			bottom: 0;
			right: 0;
			top: 0;
			align-items: center;
			display: flex;
			padding-left: $btn-padding-y;
			padding-right: $btn-padding-y;
			text-transform: uppercase;
			font-size: 14px;
		}

		&:hover::before {
			border-color: black;
		}

		&:active::before {
			background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
		}
	}
}

.pmd-input-group {
	.form-control[type="file"] {
		border: none;
		border-bottom: solid $textfield-border-height $textfield-border-color;
		border-radius: 0;
		background: transparent;
	}
}

.pmd-input-group-outline {
	.form-control[type="file"] {
		height: $textfield-outline-height;
		padding: $textfield-outline-padding-y $textfield-outline-padding-x;
		color: $textfield-color;
		background-color: transparent;
		border: $textfield-outline-border-height solid $textfield-outline-border-color;
		border-radius: $textfield-outline-border-radius;
	}
}

.pmd-input-group-filled {
	.form-control[type="file"] {
		padding: $textfield-filled-padding-y $textfield-filled-padding-x;
		color: $textfield-color;
	}
}


.pmd-file,
.pmd-file-outline,
.pmd-file-filled {
	&.form-control[type="file"] {
		position: relative;
		font-size: $textfield-font-size;
		color: $textfield-color;
		padding: $textfield-padding-top 0 $textfield-padding-bottom;
		background-color: transparent;
		border-color: $textfield-border-color;
		outline: none;
		box-shadow: none;

		&::-webkit-file-upload-button {
			visibility: hidden;
			display: none;
		}

		&::before {
			// content: 'Browser';
			outline: none;
			cursor: pointer;
			border-left: none;
			@include border-radius(0 $border-radius $border-radius 0);
			background-color: $custom-file-bg;
			color: $custom-file-color;
			position: absolute;
			bottom: 0;
			right: 0;
			top: 0;
			align-items: center;
			display: flex;
			padding-left: $btn-padding-y;
			padding-right: $btn-padding-y;
			text-transform: uppercase;
			font-size: 14px;
		}

		&:hover::before {
			border-color: black;
		}

		&:active::before {
			background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
		}

		&.is-invalid {
			border-color: theme-color("danger");
		}

		&.is-valid {
			border-color: theme-color("success");
		}
	}
}

.pmd-file {
	&.form-control[type="file"] {
		border: none;
		border-bottom: solid $textfield-border-height $textfield-border-color;
		border-radius: 0;
	}
}

.pmd-file-outline {
	&.form-control[type="file"] {
		height: $textfield-outline-height;
		padding: $textfield-outline-padding-y $textfield-outline-padding-x;
		color: $textfield-color;
		background-color: transparent;
		border: $textfield-outline-border-height solid $textfield-outline-border-color;
		border-radius: $textfield-outline-border-radius;

		&:focus {
			border: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
			box-shadow: none;
			outline: none;
			// height: $textfield-outline-focus-height;
		}
	}
}

.pmd-file-filled {
	&.form-control[type="file"] {
		height: $textfield-filled-height;
		padding: $textfield-filled-padding-y $textfield-filled-padding-x;
		color: $textfield-color;
		background-color: $textfield-filled-bg;
		border: none;
		border-bottom: $textfield-filled-border-height solid $textfield-filled-border-color;
		border-radius: $textfield-filled-border-radius $textfield-filled-border-radius 0 0;

		&:focus {
			border-bottom: $textfield-filled-focused-border-height solid $textfield-focused-bg;
			box-shadow: none;
			outline: none;
		}
	}
}


.pmd-custom-file {
	.custom-file-input {
		&:focus {
			&~.custom-file-label {
				box-shadow: none;
				border-color: $textfield-focused-bg;
				border-width: 2px;
			}
		}
	}

	.custom-file-label {
		background-color: transparent;
		border: none;
		border-bottom: solid $textfield-border-height $textfield-border-color;
		outline: none;
		box-shadow: none;
		border-radius: 0;
		font-size: $textfield-font-size;
		padding: $textfield-padding-y;
		padding-left: 0;
		color: $textfield-color;

		&::after {
			border-left: none;
			@include border-radius(4);
			background-color: $custom-file-bg;
			color: $custom-file-color;
		}
	}
}

.pmd-file-input-sm {
	.pmd-file-wrapper {
		display: block;

		.pmd-drop-container {
			height: auto;
			padding: 5px;

			flex-direction: column;

			@media (min-width: 768px) {
				flex-direction: row;
			}
		}
	}

	.pmd-multiple-files-list {
		width: 100%;
		max-height: none;
		margin-left: 0;

		.pmd-file-preview {
			padding: 0.313rem;

			.pmd-file-thumb {
				width: 50px;
				min-width: 50px;
				height: 50px;
			}
		}
	}
}

.pmd-custom-file-outline {
	height: $textfield-outline-height;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
	position: relative;

	.custom-file-label {
		height: $textfield-outline-height;
		padding: $textfield-outline-padding-y $textfield-outline-padding-x;
		color: $textfield-color;
		background-color: transparent;
		border: $textfield-outline-border-height solid $textfield-outline-border-color;
		border-radius: $textfield-outline-border-radius;
		left: 0;
		z-index: 1;

		&::after {
			height: $textfield-outline-inner-height;
			padding: $textfield-outline-padding-y $textfield-outline-padding-x;
			border-left: none;
			@include border-radius(4px);
			background-color: $custom-file-bg;
			color: $custom-file-color;
		}
	}

	.custom-file-label,
	.custom-file-label::after {
		position: absolute;
		top: 0;
		right: 0;
		line-height: 1.4;
	}

	.custom-file-input {
		height: $textfield-outline-height;
		z-index: 2;
		margin: 0;
		opacity: 0;

		&:focus {
			&~.custom-file-label {
				border: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;
				box-shadow: none;
				outline: none;

				&::after {
					height: $textfield-outline-focus-height;
				}
			}
		}
	}
}

.pmd-custom-file-filled {
	height: $textfield-filled-height;

	.custom-file-label {
		height: $textfield-filled-height;
		padding: $textfield-filled-padding-y $textfield-filled-padding-x;
		color: $textfield-color;
		background-color: $textfield-filled-bg;
		border: none;
		border-bottom: $textfield-filled-border-height solid $textfield-filled-border-color;
		border-radius: $textfield-filled-border-radius;

		&::after {
			height: $textfield-filled-inner-height;
			padding: $textfield-filled-padding-y $textfield-filled-padding-x;
			border-left: none;
			@include border-radius(4);
		}
	}

	.custom-file-input {
		height: $textfield-filled-height;

		&:focus {
			&~.custom-file-label {
				border-bottom: $textfield-filled-focused-border-height solid $textfield-focused-bg;
				box-shadow: none;
				outline: none;

				&::after {
					height: $textfield-filled-focus-height;
				}
			}
		}
	}
}

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

.pmd-checkbox {
	.custom-control-input {

		.was-validated &:invalid,
		&.is-invalid {
			~.custom-control-label {
				&::before {
					background-color: transparent;
					box-shadow: none;
					border-color: $checkbox-check-color;
				}

				&::after {
					background-color: transparent;
				}
			}
		}
	}
}

// help block
.help-block {
	font-size: 14px;
	margin-top: 0;
}

.has-error-text {
	display: none;
}

// Dark Theme
.text-white {
	.pmd-textfield {
		label {
			color: $textfield-dark-label-color;

			&.is-invalid {
				color: theme-color("danger");
			}

			&.is-valid {
				color: theme-color("success");
			}
		}

		.form-select {
			background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down-white.svg) no-repeat right 0.35rem center/22px 22px;
		}

		.form-select.is-invalid,
		.was-validated .form-select:invalid {
			background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down-white.svg) no-repeat right 0.35rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
		}

		.form-select.is-valid,
		.was-validated .form-select:invalid {
			background: url(../../../../shared/propeller/assets/images/baseline-arrow_drop_down-white.svg) no-repeat right 0.35rem center/22px 22px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem);
		}

		.form-control,
		.form-select {
			color: $textfield-dark-color;
			border-bottom-color: $textfield-dark-border-color;

			&:-webkit-autofill,
			&:-webkit-autofill:hover,
			&:-webkit-autofill:focus {
				-webkit-text-fill-color: $textfield-dark-color;
			}

			&.is-invalid {
				border-bottom-color: theme-color("danger");
				background-color: transparent;
			}

			&.is-valid {
				border-bottom-color: theme-color("success");
				background-color: transparent;
			}
		}

		select {
			option {
				color: $textfield-color;
			}
		}

		.form-control-plaintext {
			color: $textfield-dark-color;
		}

		&.pmd-textfield-outline {

			.pmd-textfield-outline-left,
			.pmd-textfield-outline-right,
			.pmd-textfield-outline-middle {
				border-color: $textfield-dark-border-color;
			}

			&.pmd-textfield-floating-label-active {
				label {
					color: $textfield-focused-bg;
				}

				.pmd-textfield-outline-left,
				.pmd-textfield-outline-right,
				.pmd-textfield-outline-middle {
					border-color: $textfield-focused-bg;
				}
			}

			&.pmd-textfield-is-valid {

				.pmd-textfield-outline-left,
				.pmd-textfield-outline-right,
				.pmd-textfield-outline-middle {
					border-color: theme-color("success");
				}

				label {
					color: theme-color("success");
				}
			}

			&.pmd-textfield-is-invalid {

				.pmd-textfield-outline-left,
				.pmd-textfield-outline-right,
				.pmd-textfield-outline-middle {
					border-color: theme-color("danger");
				}

				label {
					color: theme-color("danger");
				}
			}
		}

		&.pmd-textfield-filled {
			.pmd-textfield-filled-wrapper {
				background-color: $textfield-filled-dark-bg;
			}

			&.pmd-textfield-floating-label-active {
				label {
					&.is-invalid {
						color: theme-color("danger");
					}

					&.is-valid {
						color: theme-color("success");
					}
				}
			}
		}
	}

	.pmd-file,
	.pmd-file-outline,
	.pmd-file-filled {
		&.form-control[type="file"] {
			color: $textfield-dark-color;
			border-bottom-color: $textfield-dark-border-color;

			&.is-invalid {
				border-color: theme-color("danger");
			}

			&.is-valid {
				border-color: theme-color("success");
			}
		}
	}

	.pmd-file-filled {
		&.form-control[type="file"] {
			background-color: $textfield-filled-dark-bg;
		}
	}

	// .pmd-custom-file-outline {
	// 	.custom-file-label {
	// 		color: $textfield-dark-color;
	// 		border-color:$textfield-dark-border-color;
	// 		&.is-invalid{
	// 			border-color:theme-color("danger");
	// 		}
	// 		&.is-valid{
	// 			border-color:theme-color("success");
	// 		}
	// 	}
	// }
	// .pmd-custom-file-filled {
	// 	.custom-file-label{
	// 		color: $textfield-dark-color;
	// 		border-color:$textfield-dark-border-color;
	// 		background-color: $textfield-filled-dark-bg;
	// 		&.is-invalid{
	// 			border-color:theme-color("danger");
	// 		}
	// 		&.is-valid{
	// 			border-color:theme-color("success");
	// 		}
	// 	}
	// }
}