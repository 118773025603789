.lead-admin-dashboard {
    .graph-details {
        // padding: 15px 0 5px;
    }

    .bullet-point {
        margin: 13px 0;

        span {
            display: block;
            border: 3px solid;
            border-radius: 50%;
            margin: 0 6px 0 0;
            background-color: #ffffff;
            padding: 2px;
        }

        &.bullet-yellow {
            span {
                border-color: #F1D981;
            }

        }

        &.bullet-green {
            span {
                border-color: #5BE5A7;
            }

        }

        &.bullet-red {
            span {
                border-color: #FF6D6D;
            }

        }

        &.bullet-pink {
            span {
                border-color: #ED61EE;
            }

        }
    }

    .details-wrap {
        margin: 10px 0;

        .details-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;

            color: #646464;

        }

        .details-value {
            font-weight: 600;
            font-size: 16px;
            line-height: 25px;
            color: #000000;
            margin-bottom: 0;
        }

    }

    .card-user-analytics {
        @include media-breakpoint-down (md) {

            .col-md-6 {
                border-right: none !important;
                border-bottom: 1px solid rgba(0, 0, 0, .175) !important;

                .icon-card {
                    padding: 20px !important;
                }
            }

            .col-md-6:last-child {
                border-bottom: none !important;
            }

        }
    }

    .icon-dashborad-summary {
        /* icon */

        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        /* White */
        background: #FFFFFF;
        /* Neutral/Stroke */
        border: 1px solid #D2D6DB;
        border-radius: 8px;

    }

    .table-lead-dashboard {
        th {
            padding: 8px;
        }

        tr {
            td {
                padding: 8px;
            }


            .bg-highlight-td {
                background-color: #fff9e3;
                border-right: 1px solid #D7DBDF;
                font-weight: 500;
            }
        }
    }

    .pmd-card {
        .card-header {
            border-color: inherit;
            padding: 10px 20px;

            .title-value {
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                /* identical to box height */
                text-align: right;

                color: #000000;


            }
        }
    }
}