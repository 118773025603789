.pmd-select {
    .ng-placeholder {
        // color: #838B9B !important;
        color: #838B9B;
    }
}

.pmd-textfield-floating-label {
    .pmd-select {
        &.pmd-select-outline {
            .ng-select-container {
                overflow: visible;
            }

            &.is-invalid {
                border-color: theme-color("danger");
            }
        }

        .ng-placeholder {
            display: block;
            position: absolute;
            // margin-top: 1px;
            // margin-left: 1px;
            // color: $pmd-text-secondary;
            color: $black;
            font-weight: 400;
            transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        &.ng-select-opened,
        .ng-has-value {
            .ng-placeholder {
                display: block;
                transform: translateY(-22px);
                font-size: 0.75rem;
                color: $black;
                background-color: #fff;
                padding: 0 5px;
                margin-left: -3px;
                font-weight: 500;
            }
        }

        &.ng-select-focused,
        &.ng-select-opened {
            .ng-placeholder {
                color: $gray-600;
            }
        }

        // &.ng-select-opened {
        //     .ng-placeholder{
        //         transform: translateY(-11px);
        //         font-size: 0.8746875rem;
        //         color: #555555;
        //     }
        // }
    }
}

.pmd-input-group-outline {
    &.pmd-textfield-floating-label {
        &.pmd-input-group-icon {
            .input-group-prepend {
                &~.pmd-textfield-outline {
                    .ng-value-container {
                        margin-left: 30px;
                    }

                    .ng-select-opened,
                    .ng-has-value {
                        .ng-value-container {
                            .ng-placeholder {
                                transform: translate(-30px, -22px);
                            }
                        }
                    }

                    .ng-has-value {
                        .ng-value-container {
                            .ng-placeholder {
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.pmd-select {
    background-color: transparent;
    border: none;
    border-bottom: solid $textfield-border-height $textfield-border-color;
    outline: none;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
    font-weight: $font-weight-medium;
    font-size: 0.75rem;
    color: $dropdown-link-color;
    flex-grow: inherit;

    @media (min-width: 1560px) {
        font-size: $textfield-font-size;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: none;
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: $textfield-color;
    }

    .ng-select-container {
        .ng-value-container {
            .ng-input {
                &>input {
                    padding: 0;
                }
            }
        }
    }

    &.ng-select-disabled {
        >.ng-select-container {
            background-color: #FBFBFB;

            .ng-placeholder {
                user-select: none;
                cursor: default;
            }
        }
    }

    .ng-spinner-loader {
        display: none;
    }

    .ng-clear-wrapper {
        .ng-clear {
            font-size: 18px;
            color: pmd-theme-color(icon, dark);
        }
    }

    .ng-arrow-wrapper {
        width: 20px;
        // padding-right: 5px;
        display: flex;
        align-items: center;

        &:hover {
            .ng-arrow {
                border-top-color: darken($border-color, 40);
            }
        }

        .ng-arrow {
            // border-color: darken($border-color, 20) transparent transparent;
            // border-style: solid;
            // border-width: 5px 5px 2.5px;
            // height: 18px !important;
            // width: 18px !important;
            // font-family: 'Material Icons';
            // font-weight: normal;
            // font-style: normal;
            // font-size: 20px;
            // line-height: 1;
            // letter-spacing: normal;
            // text-transform: none;
            // display: inline-block;
            // vertical-align: middle;
            // white-space: nowrap;
            // word-wrap: normal;
            // direction: ltr;
            // -webkit-font-feature-settings: 'liga';
            // -webkit-font-smoothing: antialiased;
            // &::before{
            //     content: "\e5cf";
            //     color: rgba(0, 57, 102, 0.3);   
            // }
            width: 12px !important;
            height: 12px !important;
            display: inline-block;
            position: relative;
            transition: 0.4s ease;
            margin-top: 2px;
            text-align: left;
            transform: rotate(45deg);
            top: 8px;

            &:before,
            &:after {
                position: absolute;
                content: '';
                display: inline-block;
                width: 8px;
                height: 2px;
                background-color: #878787;
                transition: 0.4s ease;
            }

            &:after {
                position: absolute;
                transform: rotate(90deg);
                top: -3px;
                left: 3px;
            }
        }
    }

    &.ng-select-opened {
        >.ng-select-container {
            .ng-arrow {
                top: 4px;

                &:before,
                &:after {
                    width: 12px;
                }

                &:before {
                    transform: translate(3px, 0);
                }

                &:after {
                    transform: rotate(90deg) translate(3px, 0);
                }

                &:hover {
                    border-color: transparent transparent darken($border-color, 60);
                }
            }
        }
    }

    .ng-select-container {

        // height: $input-height;
        .ng-value-container {
            // min-height: 21px;
            align-items: center;
        }
    }

    .ng-has-value .ng-placeholder {
        display: none;
    }

    .ng-placeholder {
        color: $ng-placeholder-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.pmd-select-outline {
        padding-left: $textfield-outline-no-floating-padding-x;
        padding-right: $textfield-outline-no-floating-padding-x;
        border: $textfield-outline-border-height solid $textfield-outline-border-color;
        border-radius: $border-radius;

        .ng-select-container {
            padding-top: calc(.57rem - 1px);
            padding-bottom: calc(.57rem - 1px);

            // height: $textfield-outline-height;
            @media (min-width: 1560px) {
                padding-top: calc(.65rem - 1px);
                padding-bottom: calc(.65rem - 1px);
            }
        }

        &.ng-select-focused,
        &.ng-select-opened {
            padding-left: $textfield-outline-no-floating-focused-padding-x;
            padding-right: $textfield-outline-no-floating-focused-padding-x;
            border: $textfield-outline-border-focused-height solid $textfield-outline-focused-bg;

            .ng-select-container {
                padding-top: $textfield-outline-no-floating-focused-padding-y;
                padding-bottom: $textfield-outline-no-floating-focused-padding-y;
                // height: $textfield-outline-focus-height;
            }
        }

        &.pmd-select-sm {
            padding-left: calc(0.75rem - 1px);
            padding-right: calc(0.75rem - 1px);
            font-size: 14px;

            .ng-select-container {
                // height: calc(1.25em + 1rem);
                padding-top: calc(0.5rem - 1px);
                padding-bottom: calc(0.5rem - 1px);

            }

            &.ng-select-focused,
            &.ng-select-opened {
                // padding-left: calc(0.75rem - 2px);
                // padding-right: calc(0.75rem - 2px);

                .ng-select-container {
                    // height: calc(1.3em + 1rem - 2px);
                    // padding-top: calc(0.5rem - 2px);
                    // padding-bottom: calc(0.5rem - 2px);
                }
            }

            &.ng-select-opened .ng-arrow {
                margin-top: 5px;
            }

            .ng-arrow {
                margin-top: 2px;
            }
        }

        &.pmd-select-xs {
            padding-left: calc(0.65rem - 1px);
            padding-right: calc(0.65rem - 1px);
            font-size: 14px;

            .ng-select-container {
                height: calc(1em + 1rem);
                padding-top: calc(0.25rem - 1px);
                padding-bottom: calc(0.25rem - 1px);

                .ng-value-container {
                    line-height: 21px;
                }
            }

            &.ng-select-focused {
                padding-left: calc(0.65rem - 2px);
                padding-right: calc(0.65rem - 2px);

                .ng-select-container {
                    height: calc(1em + 1rem - 2px);
                    padding-top: calc(0.25rem - 2px);
                    padding-bottom: calc(0.25rem - 2px);
                }
            }
        }
    }

    &.pmd-select-dark {
        .ng-placeholder {
            color: rgba(247, 249, 250, 0.87);
        }

        .ng-value-label {
            color: rgba(247, 249, 250, 0.87);
        }

        .ng-dropdown-panel {
            min-width: 180px;
        }

        &.pmd-select-outline {
            border-color: rgba(247, 249, 250, 0.24);
        }

        .ng-select-container {
            &:hover {
                .ng-value-label {
                    color: #fff;
                }

                .ng-value-container {
                    .ng-input>input {
                        color: rgba(247, 249, 250, 1);
                    }
                }

                .ng-arrow-wrapper {
                    .ng-arrow {

                        &:after,
                        &::before {
                            background-color: #fff;
                        }
                    }
                }
            }

            .ng-value-container {
                cursor: pointer;

                .ng-input>input {
                    // color: #fff;
                    color: rgba(247, 249, 250, 0.87);
                    cursor: pointer;
                }
            }
        }
    }

    .ng-dropdown-panel {
        background: $ng-select-bg;
        box-shadow: $dropdown-box-shadow;
        border: solid $border-width $border-color;
        border-radius: $border-radius;
        left: 0;
        overflow: hidden;
        z-index: 1031 !important;

        &.ng-select-top {
            bottom: 100%;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            // border-bottom-color: #e6e5e5;
            margin-bottom: $textfield-outline-border-focused-height;
        }

        .ng-dropdown-panel-items {
            .ng-option {
                padding: $dropdown-item-padding-y $dropdown-item-padding-x;
                border-bottom: $list-group-border-width solid $list-group-border-color;
                user-select: none;
                white-space: initial;
                cursor: pointer;
                font-size: 0.75rem;

                @media(min-width:1560px) {
                    font-size: 0.875rem;
                }

                .pmd-checkbox-option {
                    display: flex;
                    align-items: center;

                    p {
                        line-height: 1;
                    }

                    .pmd-option-check {
                        margin-right: 0.5rem;

                        &:before {
                            content: '\e835';
                            opacity: 0.54;
                        }
                    }
                }

                &.ng-option-marked {
                    background: $pmd-theme-hover;
                }

                &.ng-option-disabled {
                    cursor: default;
                    opacity: 0.54;
                }

                &.ng-option-selected {
                    background: $pmd-theme-selection;
                    color: $ng-select-highlight;

                    .pmd-checkbox-option {
                        .pmd-option-check {
                            &:before {
                                content: '\e834';
                                opacity: 1;
                                color: $pmd-theme-selection-checkbox;
                                //   box-shadow:$textfield-outline-focus-box-shadow;
                            }
                        }
                    }
                }

                .pmd-option-title {
                    color: $pmd-list-title-color;
                    font-size: $pmd-list-font-size;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:first-child {
                        margin-bottom: 0.5rem;
                    }
                }

                .pmd-option-subtitle {
                    color: $pmd-list-subtitle-color;
                    font-size: $pmd-list-subtitle-font-size;
                    margin-top: -0.25rem;

                    &:first-child {
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }

                .pmd-option-text:last-child,
                .pmd-option-subtitle:last-child {
                    margin-bottom: 0;
                }

                .pmd-option-subtitle+.pmd-option-subtitle {
                    margin-top: 0;
                }

                .pmd-option-text {
                    color: $pmd-list-text-color;
                    font-size: $pmd-list-text-font-size;
                }
            }
        }
    }
}

.pmd-input-group-outline {
    .pmd-select {
        &.ng-select-multiple {
            .ng-input {
                display: flex;
                align-items: center;
            }

            .ng-value {
                background-color: rgba($pmd-theme-primary, 0.12);
                border-radius: 20px;
                color: $pmd-theme-primary;
                padding: 0.15rem $space12 0.4rem;
                line-height: 1;
                margin: $space4;

                &.ng-value-disabled {
                    opacity: 0.54;
                }

                .ng-value-icon {
                    font-weight: $font-weight-bold;
                    font-size: 18px;
                    opacity: 0.54;
                    margin-right: 8px;
                }

                .clear-selection {
                    vertical-align: middle;
                }

                .ng-value-label {
                    font-size: $font-size-sm;
                    font-weight: $font-weight-medium;
                }
            }

            &.pmd-select-outline {
                padding-left: $textfield-outline-no-floating-padding-x;
                padding-right: $textfield-outline-no-floating-padding-x;
                border: $textfield-outline-border-height solid $textfield-outline-border-color;
                border-radius: $border-radius;

                .ng-select-container {
                    padding-top: $textfield-outline-no-floating-padding-y;
                    padding-bottom: $textfield-outline-no-floating-padding-y;
                }

                .ng-has-value {
                    .ng-value-container {
                        margin-top: -6px;
                        margin-bottom: -6px;
                        margin-left: -4px;
                        margin-left: -4px;
                    }
                }

                &.pmd-select-xs {
                    .ng-select-container {
                        height: calc(1.1em + 1rem);
                        padding-top: calc(0.3rem - 1px);
                        padding-bottom: calc(0.3rem - 1px);

                        .ng-value-container {
                            line-height: 21px;
                        }
                    }
                }
            }

            .ng-arrow-wrapper {
                align-items: flex-start;
                margin-top: $space4;
            }

            &.pmd-select-dark {
                &.pmd-select-outline {
                    border-color: #B3B3B3;

                }
            }
        }
    }

    &.pmd-textfield-floating-label {
        &.pmd-input-group-icon {
            .input-group-prepend {
                &~.pmd-textfield-outline {
                    .pmd-select {
                        .ng-value-container {
                            margin-left: 30px;
                        }
                    }
                }
            }
        }
    }
}

.input-group>.pmd-select {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group>.pmd-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ng-dropdown-footer {
    padding: 10px;
}

.pmd-textfield-is-invalid {
    .pmd-select {
        &.pmd-select-outline {
            border-color: theme-color("danger");

            .ng-placeholder {
                color: theme-color("danger");
            }
        }
    }
}

.ts-more {
    display: flex;

    .ng-value-label {
        align-self: center;
        margin-top: 3px;
    }
}


// Input Group
.pmd-input-group-outline {
    &.pmd-input-group-icon {
        &.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
            border-radius: $border-radius;
        }

        .input-group-text {
            ~.pmd-select {
                padding-left: 44px;
            }
        }
    }
}

.ts-search {
    .pmd-select.pmd-select-outline.ng-select-multiple .ng-value {
        margin: 0.25rem 0.25rem 0.25rem;
        display: flex;
        align-items: center;
    }
}

.pmd-select.pmd-select-outline.pmd-select-sm.ng-select-multiple .ng-select-container {
    height: auto
}