.khr-entity-card {
    .pmd-card {
        display: flex;
    }

    .card-body {
        margin-top: 0 !important;
        display: flex;
        flex-direction: column;
    }

    .khr-view-detail-link {
        align-items: self-end;
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    .khr-add-btn {
        margin-left: 25px !important;
    }



    white-space: normal;
    word-break: break-word;


    .khr-entity-card .khr-add-btn {
        margin-left: 0.5rem !important;
    }
}