.with-label {
  display: flex;

  &::before {
    height: 24px;
    margin: 0.5rem 1.25rem 0 0;
    width: 1px;
    display: block;
    background-color: $border-color;
    content: "";
  }

  .pmd-switch {
    display: flex;
    align-items: center;
    padding: 0 !important;

    .switch-label-caption {
      &.pmd-switch-off {
        padding-right: 0.5rem;
      }

      &.pmd-switch-on {
        padding-left: 0.5rem;
      }
    }

    .form-check-input {
      margin: 0;
    }
  }
}

.pmd-switch-sm {
  width: 38px;
  margin: 0 4px;

  .form-check-input {
    height: 1.2rem;
    width: 2.5em;
    margin-left: 0;
    font-size: 13px;
    margin-top: 0;

    &::before {
      margin-left: 3px !important;
    }

    &:checked::before {
      margin-left: 17px !important;
    }
  }

  .pmd-switch {
    min-height: auto;
    margin: 0 1px !important;
    padding: 0 !important;
  }
}

.pmd-switch {
  cursor: pointer;

  .switch-label-caption {
    font-weight: $font-weight-medium;
    color: $gray-700;

    &.pmd-switch-off {
      order: 0;
    }

    &.pmd-switch-on {
      order: 3;
    }
  }

  .form-check-input {
    cursor: pointer;
    order: 2;
    background-color: rgba($dark, 0.2);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    border: none;
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: none;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    &:checked {
      background-color: $secondary;

      &::before {
        content: "";
        margin-left: 26px;
        font-weight: $font-weight-bold;
      }

      &:focus {
        box-shadow: 0 0 0 0.25rem rgba($secondary, 0.25);
      }

      &~.pmd-switch-off~.pmd-switch-on {
        color: $dark;
      }
    }

    &:not(:checked) {
      &~.pmd-switch-off {
        color: $dark;
      }
    }

    &::before {
      content: "";
      color: rgba($dark, 0.74);
      font-family: "Material Icons";
      font-weight: $font-weight-bold;
      margin-left: 8.5px;
      transition: margin 0.15s ease-in-out;
    }
  }
}

.pmd-switch-custom {
  display: flex;
  align-items: center;

  .pmd-switch {
    margin: 0 0.5rem;

    .switch-label-caption {
      display: none;
    }
  }

  label {
    font-size: 0.749875rem;
    text-transform: none;
    color: #838d91;

    &.active {
      opacity: 1;
      color: #1b2e35;
    }
  }

  &.with-label {
    align-items: center;
  }

  &.both-true-toggle {
    .form-check-input {
      background-color: $secondary;

      &:before {
        content: "";
      }
    }
  }
}