.pmd-input-group-outline {
    .input-group-text {
        border-color: $textfield-outline-border-color;
        background-color: $input-group-text-bg;
        font-weight: $font-weight-semibold;
        color: $gray-700;
        font-size: $font-size-base;
    }

    .pmd-select {
        border-color: $textfield-outline-border-color;
        background-color: $input-group-text-bg;

        .ng-placeholder {
            font-weight: $font-weight-semibold;
            color: $black !important;
            font-size: $font-size-base;
        }

    }

    .pmd-select:not(.pmd-select-dark) {
        .ng-value-label {
            color: $black;
        }
    }
}

.pmd-input-group-icon {
    &.pmd-textfield-is-invalid {
        .input-group-text {
            height: 48px;
        }

        .invalid-feedback {
            padding-left: 0;
        }
    }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-image: none;
}

.khr-input-group-outline-icon {
    &.input-group-sm .input-group-text {
        border-radius: 8px;
    }

    &.khr-icon-r {
        .pmd-textfield-outline {
            .form-control {
                border-radius: 0.5rem;

                // &[placeholder] {
                //     border-top-left-radius: 0;
                //     border-bottom-left-radius: 0;
                // }
            }

        }
    }

    &.khr-icon-l {
        .pmd-textfield-outline {
            .form-control {
                border-radius: 0.5rem;
            }
        }
    }

    &.khr-icon-rl {
        .pmd-textfield-outline {
            .form-control {
                border-radius: border-radius;
            }
        }
    }

    .pmd-input-icon-absolute {
        &.input-group-text {
            position: absolute;
            bottom: 0;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            z-index: 1025;

            &:last-child {
                right: 10px;
            }

            &:first-child {
                left: 0;
            }
        }
    }
}