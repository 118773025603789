.pk-login-signup-card {
    max-width: 490px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
        padding: 0 15px;

        h2,
        .h2 {
            font-size: 1.4rem;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 30px 15px;
    }
}

.pk-login-contener {
    >.row {
        height: 100vh;
    }

    @include media-breakpoint-down(sm) {
        padding: 10px 20px;
        background-color: #dfe9ef;

        >.row {
            border-radius: $border-radius;
            overflow: hidden;
            height: inherit;
        }
    }
}

.navbar-brand {
    @include media-breakpoint-down(sm) {
        // padding-bottom: 0 !important;
    }

    svg {
        @include media-breakpoint-down(sm) {
            height: 28px;
        }
    }
}

.pk-login-form {
    margin-top: auto;
    margin-bottom: auto;

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;

        .card-title {
            font-size: 1.125rem;
        }
    }

    @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
    }

    h2 {
        font-size: 1.125rem;

        @media(min-width:1559px) {
            font-size: 1.45rem;
        }

    }
}

.logo-d {
    max-width: 168px;

    @media (min-width:1560px) {
        max-width: 220px;
    }
}

.pk-login-signup-right-card {
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 680px;

    @include media-breakpoint-down(sm) {
        padding: 22px 10px;

        h1 {
            margin: 15px 0;


        }
    }

    h1 {
        font-size: 28px;

        @media(min-width:1559px) {
            font-size: 2.25rem;
        }
    }



    .img_graphics {
        @include media-breakpoint-down(sm) {
            order: -1;
        }
    }

    .lead {
        color: $gray-600;
        font-weight: 600;

        @media(max-width:1559px) {
            font-size: 0.875rem;
        }
    }
}

.pk-gradient-bg {
    background: rgba($color: #EBB901, $alpha: 0.1);

    @include media-breakpoint-down(sm) {
        border-radius: 0 0 $border-radius $border-radius;
    }
}

.pk-login-signup-footer {
    .pmd-site-info {
        text-align: center;
        //   color: #fff;

        strong {
            //  color: #fff;
        }
    }

    .pmd-footer-nav {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            border-right: 1px solid rgba(#fff, 0.54);

            a {
                //   color: rgba(#fff, 0.74);
            }

            line-height: 1;
            display: flex;

            &:last-child {
                border-right: none;
            }
        }
    }
}

.khr-forgot-pw {

    .pmd-textfield .form-control {
        height: calc(1.875em + 1rem);
        font-size: 20px;
    }
}

.date-dropdown.position-absolute-right {
    @include media-breakpoint-down(lg) {
        width: 100%;
        margin-bottom: 1.5rem;
        position: static;
    }
}

@media only screen and (max-width: 1024px) {
    .invoice-count .count-reviewed {
        display: block !important;
    }

    .khr-forgot-pw .pmd-textfield .form-control {

        font-size: 18px !important;
    }

    .pk-login-signup-right-card {


        h1 {
            font-size: 25px;
        }

        p.lead {
            font-weight: 300;
        }

        .img_graphics {
            width: 80%;
            margin: 0 auto;
        }

    }

    .navbar>.container-fluid {
        flex-wrap: nowrap;
    }


    .dashboard-tabs .total-invoice {
        width: 40% !important;

        span {
            font-size: 11px;
        }

        .count-number {
            font-size: 28px !important;
        }

    }
}

.khr-entity-dd {
    .pmd-textfield-floating-label .pmd-select .ng-has-value .ng-placeholder {
        font-size: 10px;
    }
}

@media only screen and (max-width: 640px) {

    footer {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .pmd-footer {
            padding: 1rem 0;
        }
    }

    .pk-login-signup-card {
        padding-bottom: 20px;
    }

    .khr-entity-dd {
        order: inherit;
    }

    .pk-login-signup-right-card .img_graphics {
        margin-bottom: 0 !important;
    }

    .date-dropdown {
        .position-absolute-right {
            position: relative;
            right: 0;
            top: -12px !important;
            z-index: 9;
        }
    }
}

@media only screen and (max-width:420px) {
    .date-dropdown {
        .khr-icon-r .input-group-text {
            // padding: 2px 8px;
            font-size: 11px;
        }

        .dropdown-toggle {
            font-size: 11px;
        }
    }

    .khr-forgot-pw {
        gap: 0.5rem !important;
    }

    .khr-forgot-pw .pmd-textfield .form-control {
        font-size: 15px !important;
        padding: 9px;
    }
}