.text-muted {
    color: pmd-theme-color(hint, dark);
    font-size: $font-size-sm;
}

.pf-fw-semibold {
    font-weight: $font-weight-semibold;
}

.pf-text-muted-fwn {
    color: pmd-theme-color(hint, dark);
    font-weight: $font-weight-normal;
}

.pf-fwn {
    font-weight: $font-weight-normal;
}

.ff-ns {
    font-family: $font-family-base;
}

.pf-fwl {
    font-weight: $font-weight-light;
}

.pf-text-secondary {
    color: pmd-theme-color(secondary, dark);
}

.pf-text-primary {
    color: pmd-theme-color(primary, dark);
}

.bg-blue-light {
    background-color: #EBF0F8;
    border-top: #E1E7F4 solid 1px;
}

.fs-12 {
    font-size: $font-size-sm !important;
}

.fs-14 {
    font-size: 14px;
}

sub {
    bottom: 0;
}

.pmd-select-bg-white {
    background-color: $white;
    border-radius: $border-radius;

    .pmd-select.pmd-select-outline {
        @include box-shadow($card-shadow);

        &.ng-select-focused,
        &.ng-select-opened {
            border-color: theme-color("secondary");
            padding-left: 43px;
        }
    }
}

.pf-footer-nav {
    li {
        h2 {
            @include media-breakpoint-down(xs) {
                font-size: 18px;
            }
        }
    }
}

.pf-footer-nav-mobile {
    @include media-breakpoint-down(xs) {
        background-color: #fff;
        margin-bottom: 16px;
        padding: 10px 0;
        margin-left: -8px;
        margin-right: -8px;
        margin-top: -16px;

        li {
            padding: 8px 16px;
            border-bottom: solid $border-color $border-width;

            &:last-child {
                border: none;
            }
        }
    }
}

.pf-select-page {
    max-width: 68px;
}

.pf-menu-tooltip {
    margin-left: 4px;
}

.pmd-body-open {
    .pf-menu-tooltip {
        display: none;
    }
}

.pagination-container {
    @include media-breakpoint-down(xs) {
        justify-content: center;
        text-align: center;

        nav {
            display: flex;
            justify-content: center;
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 8px !important;
        }
    }
}

.shorting-btn {
    .btn {
        text-transform: none;
        border-radius: 8px;

        @include media-breakpoint-down(sm) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            align-items: center;
        }

        &.dropdown-toggle {
            @include media-breakpoint-down(sm) {
                &::after {
                    display: none;
                }

                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
            }
        }

        i {
            opacity: 0.54;

            @include media-breakpoint-down(sm) {
                margin-right: 0 !important;
                margin: 0;
            }
        }
    }
}

.pf-cursor-pointer {
    cursor: pointer;
}

.pr-35 {
    padding-right: 35px !important;
}

.text-grey {
    color: #BFC0BB;
}

.mt-34 {
    margin-top: 34px;
}

.text-link {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
}

.w-180 {
    width: 140px;
    display: block;
}

@media only screen and (max-width:500px) {
    .mb-xs-2 {
        margin-bottom: 0.5rem !important;
    }
}

.ml-n10 {
    margin-left: -10px;
}

.ns-fs14-fwm {
    font-size: 14px;
    font-weight: $font-weight-medium;

    @media (max-width:1559px) {
        font-size: 12px;
    }
}

.khr-fwm {
    font-weight: $font-weight-medium;
}