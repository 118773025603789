.pmd-table {
    &.table {
        th {
            vertical-align: middle;
        }

        tr {
            &.pf-table-row-inactive {
                background-color: #FEFCF0;
            }
        }
    }
}

.table-responsive-md {
    @include media-breakpoint-down(md) {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }
}

.pmd-table-card-md {
    &.pmd-table {
        &.table {
            .pf-column-name {
                @include media-breakpoint-down(xs) {
                    border-bottom: solid $border-color $border-width;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}


.kh-tayments-table {
    .pmd-table>.thead-bg-light {
        background-color: #FFF9E3;
    }

    .pmd-table.table th {
        vertical-align: top;
    }

    .khr-colspanheading th {
        border-left: 1px solid rgba(28, 36, 58, 0.1);
        border-right: 1px solid rgba(28, 36, 58, 0.1);
    }

    .pmd-table.table {
        .khr-child-table {
            .khr-child-table-card {
                tr {
                    &:nth-last-child(2) {
                        &::after {
                            height: 50% !important;
                            bottom: inherit !important;
                        }
                    }

                    .khr-subchild-table-card {
                        tr {
                            &:nth-last-child(2) {
                                &::after {
                                    height: 100% !important;
                                    bottom: 0 !important;
                                }
                            }

                            &:last-child {
                                &::after {
                                    height: 50% !important;
                                    bottom: inherit !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .khr-child-table {
            .khr-subchild-table {
                background-color: #fff;

                &:hover {
                    background-color: #fff !important;
                }

                >td {
                    padding: 0;
                    border: none;

                    >div {
                        margin: 0 14px 14px 14px;
                        border-radius: 8px;
                        background-color: rgba($color: #ebb901, $alpha: 0.08) !important;
                    }
                }
            }

            .khr-child-table-card {
                margin-left: 36px;
                margin-bottom: 15px;
                margin-right: 15px;
                border-radius: 4px;
                border: solid 1px rgba(41, 128, 185, 0.16);
                padding: 2px;

                .thead-bg {
                    th {
                        background-color: transparent;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    &::before {
                        content: "";
                        width: 20px;
                        height: 2px;
                        background-color: transparent;
                        position: absolute;
                    }
                }

                thead {
                    position: relative;

                    &::before {
                        content: "";
                        width: 1px;
                        height: 100%;
                        background-color: rgba(#000000, 0.15);
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        left: -20px;
                    }
                }

                tbody {
                    position: relative;

                    tr:not(.khr-subchild-table) {
                        position: relative;

                        &::before {
                            content: "";
                            width: 10px;
                            height: 1px;
                            background-color: rgba(#000000, 0.15);
                            position: absolute;
                            margin: auto;
                            top: 0;
                            bottom: 0;
                            left: -20px;
                        }
                    }

                    tr {
                        position: relative;

                        &::after {
                            content: "";
                            width: 1px;
                            height: 100%;
                            background-color: rgba(#000000, 0.15);
                            position: absolute;
                            margin: auto;
                            top: 0;
                            bottom: 0;
                            left: -20px;
                        }

                        &:last-child {
                            &.khr-subchild-table {
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .khr-subchild-table-card {
                    padding-left: 8px;

                    thead {
                        &::before {
                            left: -12px;
                        }

                        &::after {
                            left: -12px;
                        }

                        tr {
                            &::before {
                                left: -12px;
                            }

                            &::after {
                                left: -12px;
                            }
                        }
                    }

                    tbody {
                        tr:not(.khr-subchild-table) {
                            &::before {
                                left: -12px;
                            }

                            &::after {
                                left: -12px;
                            }
                        }
                    }
                }
            }

            >td {
                padding: 0;
                border: none;
            }
        }
    }
}

.khr-table-arrow,
.khr-chieldtable-arrow {
    cursor: pointer;
    transition: $transition-base;
    font-size: 20px;
}

.khr-accordion-active {
    background-color: #f8f8f8;

    .khr-table-arrow {
        transform: rotate(180deg);
    }
}

.khr-childtable-active {
    .khr-chieldtable-arrow {
        transform: rotate(180deg);
    }
}